<template>
  <ui-modal ref="modal" :callback="cb" :can-confirm="validInfo" :on-open="loadSigner">
    <template #title>
      {{ $t('forms.sign_form_modal.title') }}
    </template>
    <template #default>
      <div class="pb-2">
        {{ $t('forms.sign_form_modal.sign_form') }}
      </div>
      <form>
        <ui-view-card-field :label="$t('forms.sign_form_modal.first_name')" v-model="info.firstName" :required="true"/>
        <ui-view-card-field :label="$t('forms.sign_form_modal.last_name')" v-model="info.lastName" :required="true"/>
        <ui-view-card-field :label="$t('forms.sign_form_modal.organisation')" v-model="info.organisation"
                            :required="true"/>
      </form>
    </template>
  </ui-modal>
</template>

<script>

import {inject, ref} from 'vue'
import UiModal from '@/components/ui-modal'
import UiViewCardField from '@/components/ui-view-card-field'
import SignatureType from '@/types/signatureType'
import session from '@/util/session'

export default {
  name:       'FormSignFormModal',
  components: {UiViewCardField, UiModal},
  props:      {
    callback:      {
      type:     Function,
      required: true,
    },
    signatureType: {
      type:    String,
      default: null,
    },
  },
  setup:      (props) => {
    const modal = ref('modal')
    const info  = ref({
      signatureType: props.signatureType,
    })

    if (props.signatureType === SignatureType.TRANSPORTER) {
      if (inject('isSharelink')) {
        const driverInfo        = inject('driverInfo')
        info.value.firstName    = driverInfo?.value?.firstName
        info.value.lastName     = driverInfo?.value?.lastName
        info.value.organisation = driverInfo?.value?.organisation
      } else {
        const user              = session.getUser()
        const org               = session.getOrganisation()
        info.value.firstName    = user?.firstName
        info.value.lastName     = user?.lastName
        info.value.organisation = org?.name
      }
    }

    const processorInfo = inject('processorInfo', {})
    const loadSigner    = () => {
      switch (props.signatureType) {
        case SignatureType.ORGANISER: {
          const user              = session.getUser()
          const org               = session.getOrganisation()
          info.value.firstName    = user?.firstName
          info.value.lastName     = user?.lastName
          info.value.organisation = org?.name
          break
        }
        case SignatureType.PROCESSOR: {
          info.value.firstName    = processorInfo?.value?.firstName
          info.value.lastName     = processorInfo?.value?.lastName
          info.value.organisation = processorInfo?.value?.organisation
          break
        }
      }
    }

    const cb = async () => {
      await props.callback(info.value)
    }

    const validInfo = () => {
      return info.value.signatureType && info.value.firstName && info.value.lastName && info.value.organisation
    }

    return {
      modal,
      info,
      cb,
      validInfo,
      loadSigner,
    }
  },
}
</script>
