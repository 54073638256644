<template>
  <layout>
    <template #header>
      <ui-header :title="loading ? '' : $t('waste_identification.view.title', {id: link.documentId})"
                 :back-function="step === steps.arrive ? backToViewing : null">
        <template #actions>
          <template v-if="!loading && step === steps.viewing">
            <div class="btn-group">
              <button type="button" class="btn btn-outline-secondary dropdown-toggle" id="viewingActions"
                      data-bs-toggle="dropdown" aria-expanded="false">
                {{ $t('general.actions') }}
              </button>
              <ul class="dropdown-menu dropdown-menu-end text-large" aria-labelledby="viewingActions">
                <li>
                  <button id="changeLogBtn" type="button" class="dropdown-item btn btn-outline-secondary"
                          @click="showChangeLog()">{{ $t('waste_identification.view.view_change_log') }}
                  </button>
                </li>
                <li>
                  <button id="controlBtn" type="button" class="dropdown-item btn btn-outline-secondary"
                          @click="control()">{{ $t('transport.control') }}
                  </button>
                </li>
                <li v-if="!FormState.isEndState(form.state)">
                  <button id="editBtn" type="button" class="dropdown-item btn btn-outline-secondary" @click="edit()">
                    {{ $t('waste_identification.view.edit') }}
                  </button>
                </li>
                <li v-if="attachmentsFlag">
                  <button id="attachmentsBtn" type="button" class="dropdown-item btn btn-outline-secondary"
                          @click="showAttachments()">{{ $t('waste_identification.view.manage_attachments') }}
                  </button>
                </li>
              </ul>
            </div>
            <div class="btn-group"
                 v-if="(form?.organiserSignature && !form?.startPoint?.coordinates) || (form?.startPoint?.coordinates && !form?.endPoint?.coordinates)">
              <button v-if="form?.organiserSignature && !form?.startPoint?.coordinates" id="startBtn" type="button"
                      class="btn btn-outline-primary" @click="start()" :disabled="locating">
                {{ $t('transport.start_transport') }}
              </button>
              <button v-if="form?.startPoint?.coordinates && !form?.endPoint?.coordinates" id="arriveBtn" type="button"
                      class="btn btn-outline-primary" @click="arrive()" :disabled="locating">
                {{ $t('transport.end_transport') }}
              </button>
            </div>
          </template>
          <template v-if="!loading && step === steps.editing">
            <div class="btn-group pe-3">
              <button type="button" class="btn btn-outline-secondary" @click="stopEdit()"><i class="bi-arrow-left"/>&nbsp;{{
                  $t('general.back')
                }}
              </button>
              <button type="button" class="btn btn-outline-primary" :disabled="saving" @click="save()"><i
                  class="bi-save"/>&nbsp;{{ $t('general.save') }}
              </button>
            </div>
          </template>
          <template v-if="!loading && step === steps.control">
            <button type="button" class="btn btn-outline-secondary" @click="showEditWeightsModal()" :disabled="saving">
              <i class="bi-pencil"/>&nbsp;{{ $t('share_link.processor.edit_weights') }}
            </button>
            <button id="signForDeliveryBtn" type="button" class="btn btn-outline-primary" @click="goSign()"
                    :disabled="saving || signing || locating">
              <i class="bi-pencil-square"/>&nbsp;{{ $t('signature.sign_for_delivery') }}
            </button>
          </template>
        </template>
      </ui-header>
    </template>
    <ui-content class="pb-1">
      <spinner v-if="loading"/>
      <template v-else-if="step === steps.driverinfo">
        <div class="container-fluid">
          <form id="driver-form" class="row g-2 justify-content-center">
            <ui-view-card data-cy="information" :title="$t('shared.driver_information')" :key="driver.language">
              <ui-view-card-language v-model="driver.language" :required="true" :own-language="true"/>
              <template v-if="driver.language">
                <ui-view-card-field data-cy="driver-email" :label="$t('drivers.form.email')" v-model="driver.email"
                                    :required="true" :readonly="true"/>
                <ui-view-card-field data-cy="driver-first-name" :label="$t('drivers.form.first_name')"
                                    v-model="driver.firstName" :required="true"/>
                <ui-view-card-field data-cy="driver-last-name" :label="$t('drivers.form.last_name')"
                                    v-model="driver.lastName" :required="true"/>
                <ui-view-card-field data-cy="driver-organisation" :label="$t('drivers.form.organisation')"
                                    v-model="driver.organisation" :required="true"/>
                <br/>
                <br/>
                <div class="row g-0 justify-content-center">
                  <button id="saveDriverBtn" type="button" class="btn" :class="'btn-outline-primary'"
                          @click="saveDriver()" form="driver-form" :disabled="driverSaving"><i
                      class="bi-save"/>&nbsp;{{ $t('general.confirm') }}
                  </button>
                </div>
              </template>
            </ui-view-card>
          </form>
        </div>
      </template>
      <template v-else-if="step === steps.viewing">
        <waste-identification-form-view :form="form" :key="form.id+'-'+form.lastUpdate"/>
      </template>
      <template v-else-if="step === steps.editing">
        <waste-identification-form :item="form" :key="form.id+'-'+form.lastUpdate"/>
      </template>
      <template v-else-if="step === steps.arrive">
        <div id="arrive" class="d-flex justify-content-center">
          <h3 class="d-flex" v-if="!$isMobile">
            <span class="btn-group pe-3">
              <button id="signProcessorBtn" type="button" class="btn btn-outline-primary"
                      @click="signProcessor()">{{ $t('shared.sign_as_processor') }}</button>
              <button id="signDriverBtn" type="button" class="btn btn-outline-secondary"
                      @click="signDriver()">{{ $t('shared.sign_as_driver') }}</button>
              <button id="refuseBtn" type="button" class="btn btn-outline-secondary"
                      @click="showRefuse()">{{ $t('shared.refuse') }}</button>
            </span>
          </h3>
          <h3 v-else>
            <button style="height:5rem; width:100%" id="signProcessorBtn" type="button" class="btn btn-outline-primary"
                    @click="signProcessor()">{{ $t('shared.sign_as_processor') }}
            </button>
            <button style="height:5rem; width:100%" id="signDriverBtn" type="button" class="btn btn-outline-secondary"
                    @click="signDriver()">{{ $t('shared.sign_as_driver') }}
            </button>
            <button style="height:5rem; width:100%" id="refuseBtn" type="button" class="btn btn-outline-secondary"
                    @click="showRefuse()">{{ $t('shared.refuse') }}
            </button>
          </h3>
        </div>
      </template>
      <template v-else-if="step === steps.control">
        <waste-identification-form-view :form="form" :key="form.id+'-'+form.lastUpdate"/>
      </template>
      <template v-else-if="step === steps.processorinfo">
        <div class="container-fluid">
          <form id="processor-form" class="row g-2 justify-content-center">
            <ui-view-card data-cy="information" :title="$t('shared.processor_information')" :key="signerInfo.language">
              <template #actions>
                <button type="button" class="btn btn-sm btn-outline-secondary" @click="backToControl()">
                  {{ $t('general.back') }}
                </button>
              </template>
              <ui-view-card-language v-model="signerInfo.language" :required="true" :own-language="true"/>
              <template v-if="signerInfo.language">
                <ui-view-card-field data-cy="processor-first-name" :label="$t('user.labels.first_name')"
                                    v-model="signerInfo.firstName" :required="true"/>
                <ui-view-card-field data-cy="processor-last-name" :label="$t('user.labels.last_name')"
                                    v-model="signerInfo.lastName" :required="true"/>
                <ui-view-card-field data-cy="processor-organisation" :label="$t('user.labels.organisation')"
                                    v-model="signerInfo.organisation" :required="true"/>
                <br/>
                <br/>
                <div class="row g-0 justify-content-center">
                  <button id="signBtn" type="button" class="btn" :class="'btn-outline-primary'" @click="sign(true)"
                          form="processor-form"><i
                      class="bi-pencil-square"/>&nbsp;{{ $t('signature.sign_for_delivery') }}
                  </button>
                </div>
              </template>
            </ui-view-card>
          </form>
        </div>
      </template>
    </ui-content>
  </layout>
  <shared-link-control-qr ref="controlQRModal" :url="controlUrl" :driver-uuid="link.id"/>
  <shared-link-edit-weights ref="editWeightsModal" :form="form" :callback="saveWeightEdit" v-if="!loading"/>
  <form-change-log-modal ref="changeLogModal" :form="form"/>
  <form-attachments-modal v-if="!loading" ref="attachmentsModal" :sharelink="true" :uuid="link.id"
                          :callback="saveAttachments" :attachments="form.attachments"/>
  <form-refuse-modal ref="refuseModal" :callback="refuse"/>
</template>

<script>

import UiContent from '@/components/ui-content'
import {inject, ref, watch} from 'vue'
import UiViewCard from '@/components/ui-view-card'
import UiViewCardField from '@/components/ui-view-card-field'
import Notifier from '@/util/notifier'
import backend from '@/util/backend'
import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import SharedLinkControlQr from '@/views/shared-links/Shared-Link-Control-Qr'
import WasteIdentificationForm from '@/partials/waste-identification/Form'
import WasteIdentificationFormView from '@/partials/waste-identification/FormView'
import ShareLinkType from '@/types/shareLinkType'
import Spinner from '@/components/spinner'
import SharedLinkEditWeights from '@/views/shared-links/Shared-Link-Edit-Weights'
import geolocation from '@/services/geolocation'
import UiViewCardLanguage from '@/components/ui-view-card-language'
import FormState from '@/types/formState'
import FormChangeLogModal from '@/views/forms/shared/Change-Log-Modal'
import FormAttachmentsModal from '@/views/forms/shared/Attachments-Modal'
import WasteIdentificationModel from '@/models/WasteIdentificationModel'
import session from '@/util/session'
import FormRefuseModal from '@/views/forms/shared/Refuse-Modal.vue'

export default {
  name:       'SharedLinkDriverWasteIdentification',
  computed:   {
    FormState() {
      return FormState
    },
  },
  components: {
    FormRefuseModal,
    FormAttachmentsModal,
    FormChangeLogModal,
    UiViewCardLanguage,
    SharedLinkEditWeights,
    Spinner,
    WasteIdentificationFormView,
    WasteIdentificationForm,
    SharedLinkControlQr,
    UiHeader,
    Layout,
    UiViewCardField,
    UiViewCard,
    UiContent,
  },
  props:      {
    link: {
      type:     Object,
      required: true,
    },
  },
  setup:      (props) => {
    const $tset    = inject('$tset')
    const notifier = Notifier()

    const steps = {
      driverinfo:    'driverinfo',
      viewing:       'viewing',
      editing:       'editing',
      arrive:        'arrive',
      control:       'control',
      processorinfo: 'processorinfo',
    }
    const step  = ref(steps.driverinfo)

    $tset(props.link.language)
    const driver = ref({
      email:        props.link.email,
      firstName:    props.link.firstName,
      lastName:     props.link.lastName,
      organisation: props.link.organisation,
      language:     props.link.language,
    })
    watch(() => driver.value.language, (l) => {
      $tset(l)
    })

    const loading      = ref(true)
    const driverSaving = ref(false)
    const saveDriver   = () => {
      driverSaving.value = true
      const driverForm   = $('#driver-form')[0]
      if (!driverForm.checkValidity()) {
        // something in the form is not valid so don't allow signing
        driverForm.reportValidity()
        driverSaving.value = false
        return
      }
      backend.put(`/api/sharedlinks/${props.link.id}/userData`, {
        firstName:    driver.value.firstName,
        lastName:     driver.value.lastName,
        organisation: driver.value.organisation,
        language:     driver.value.language,
      }).then(() => {
        notifier.success('toast.user_confirmed')
        step.value = steps.viewing
      }).finally(() => {
        driverSaving.value = false
      })
    }

    const form     = ref({})
    const loadForm = () => {
      backend.get(`api/sharedlinks/${props.link.id}/document`).then((doc) => {
        form.value    = doc.data
        loading.value = false
      })
    }
    loadForm()

    const locating = ref(false)
    geolocation.watchPosition()
    const start = () => {
      locating.value = true
      geolocation.setLanguage(driver.value.language)
      notifier.notify('toast.getting_location')
      if (!geolocation.getLocation()) {
        setTimeout(() => {
          start()
        }, 3000)
      } else {
        backend.put(`api/sharedlinks/${props.link.id}/start`, {
          'coordinates':   geolocation.getLocation(),
          'firstName':     driver.value.firstName,
          'lastName':      driver.value.lastName,
          'organisation':  driver.value.organisation,
          'shareLinkUuid': props.link.id,
        }).then(() => {
          notifier.success('toast.start_location_saved')
          loadForm()
        }).catch(() => {
          notifier.error('toast.location_failed')
        }).finally(() => {
          locating.value = false
        })
      }
    }

    const arrive = () => {
      step.value = steps.arrive
    }

    const edit = () => {
      step.value = steps.editing
    }

    const saving = ref(false)
    const save   = async () => {
      const err = WasteIdentificationModel.formCheck(form.value)
      if (err) {
        const formElement = $('#waste-identification-form')[0]
        formElement.reportValidity()
        notifier.error(err)
        return false
      }

      saving.value = true
      const result = await backend.put(`api/sharedlinks/${props.link.id}/document/${props.link.documentId}`, form.value)
      saving.value = false
      if (result.status === 200) {
        notifier.success('toast.successfully_saved')
        loadForm()
      } else {
        notifier.error('toast.save_failed')
      }
      stopEdit()
    }

    const saveWeightEdit = async () => {
      saving.value = true
      const result = await backend.put(`api/sharedlinks/${props.link.id}/document/${props.link.documentId}`, form.value)
      saving.value = false
      if (result.status === 200) {
        notifier.success('toast.successfully_saved')
        loadForm()
        return true
      } else {
        notifier.error('toast.save_failed')
        return false
      }
    }

    const stopEdit = () => {
      loadForm()
      step.value = steps.viewing
    }

    const controlUrl     = ref('')
    const controlQRModal = ref('controlQRModal')
    const control        = () => {
      backend.put(`api/sharedlinks/${props.link.id}`, {
        shareLinkType: ShareLinkType.CONTROL,
      }).then((result) => {
        controlUrl.value = `${window.location.hostname === 'localhost'
                              ? window.location.hostname + ':' + 8082
                              : window.location.hostname}/#/shared-links/${result.data.id}`
      })
      controlQRModal.value.modal.open()
    }

    const refuseModal = ref('refuseModal')
    const showRefuse  = () => {
      refuseModal.value.modal.open()
    }
    const refuse      = (reason) => {
      refuseModal.value.modal.close()
      notifier.notify('toast.refusing_transport')
      backend.put(`api/documents/${form.value.id}/refuse2`, {reason: reason}).then(() => {
        notifier.success('toast.transport_refused')
        loadForm()
        step.value = steps.viewing
      })
    }

    const signer        = ref(null)
    const signerInfo    = ref({})
    const signProcessor = () => {
      signer.value     = 'PROCESSOR'
      step.value       = steps.control
      signerInfo.value = {
        'language':     form.value.processorIdentification.language,
        'organisation': form.value.processorIdentification.name,
      }
    }
    // language gets set when signer is chosen, so we change to that person's language
    watch(() => signerInfo.value.language, (l) => {
      $tset(l)
    })

    const signDriver = () => {
      signer.value     = 'DRIVER'
      step.value       = steps.control
      signerInfo.value = {
        'firstName':    driver.value.firstName,
        'lastName':     driver.value.lastName,
        'language':     driver.value.language,
        'organisation': driver.value.organisation,
      }
    }

    const signing = ref(false)
    const goSign  = () => {
      if (signer.value === 'PROCESSOR') {
        step.value = steps.processorinfo
      } else {
        sign()
      }
    }

    const sign = (checkForm = false) => {
      signing.value = true
      if (checkForm) {
        const formElement = $('#processor-form')[0]
        if (!formElement.checkValidity()) {
          // something in the form is not valid so don't allow signing
          formElement.reportValidity()
          signing.value = false
          return false
        }
      }
      performSign()
    }

    const performSign = () => {
      locating.value = true
      notifier.notify('toast.getting_location')
      if (!geolocation.getLocation()) {
        setTimeout(() => {
          performSign()
        }, 3000)
      } else {
        locating.value   = false
        const signingUrl = `api/sharedlinks/${props.link.id}/stop`
        backend.put(signingUrl, {
          'coordinates':  geolocation.getLocation(),
          'firstName':    signerInfo.value.firstName,
          'lastName':     signerInfo.value.lastName,
          'organisation': signerInfo.value.organisation,
        }).then((r) => {
          notifier.success('toast.form_signed')
          form.value = r.data
          backToViewing()
          $tset(session.getLanguage())
        }).catch(() => {
        }).finally(() => {
          signing.value = false
        })
      }
    }

    const backToViewing = () => {
      step.value = steps.viewing
    }

    const backToControl = () => {
      $tset(props.link.language)
      step.value = steps.control
    }

    const signed = () => {
      $tset(props.link.language)
      step.value    = steps.viewing
      signing.value = false
      loadForm()
    }

    const editWeightsModal     = ref('editWeightsModal')
    const showEditWeightsModal = () => {
      editWeightsModal.value.modal.open()
    }

    const changeLogModal = ref('changeLogModal')
    const showChangeLog  = () => {
      changeLogModal.value.modal.open()
    }

    const config           = inject('config')
    const attachmentsFlag  = ref(config.value.getConfig('ATTACHMENTS'))
    const attachmentsModal = ref('attachmentsModal')
    const showAttachments  = () => {
      attachmentsModal.value.modal.open()
    }
    const saveAttachments  = async () => {
      attachmentsModal.value.modal.close()
      loadForm()
    }

    return {
      loading,
      step,
      steps,
      driver,
      saveDriver,
      driverSaving,
      form,
      edit,
      stopEdit,
      save,
      saving,
      controlQRModal,
      control,
      start,
      arrive,
      controlUrl,
      refuseModal,
      showRefuse,
      refuse,
      backToViewing,
      backToControl,
      signProcessor,
      signDriver,
      signerInfo,
      signing,
      goSign,
      sign,
      signed,
      saveWeightEdit,
      editWeightsModal,
      showEditWeightsModal,
      locating,
      changeLogModal,
      showChangeLog,
      attachmentsFlag,
      attachmentsModal,
      saveAttachments,
      showAttachments,
    }
  },
}
</script>
