<template>
  <layout>
    <template #header>
      <ui-header data-cy="waste-identification-title" :title="$t('waste_identification.edit.title', {id})"
                 :back-route="`/waste-identification/view/${id}`">
        <template #actions v-if="!finalForm">
          <button data-cy="wi-check" type="button" class="btn btn-outline-secondary" @click="check()"><i
              class="bi-check"/>&nbsp;{{ $t('form.check_form') }}
          </button>
          <button data-cy="wi-save" type="button" class="btn btn-outline-primary" :disabled="saving" @click="save()"
                  form="waste-identification-form"><i class="bi-save"/>&nbsp;{{ $t('general.save') }}
          </button>
        </template>
        <template #actions v-else>
          <button data-cy="wi-check" type="button" class="btn btn-outline-secondary" @click="check()"><i
              class="bi-check"/>&nbsp;{{ $t('form.check_form') }}
          </button>
          <button data-cy="wi-cancel" type="button" class="btn btn-outline-secondary" @click="cancel()"><i
              class="bi-x"/>&nbsp;{{ $t('general.cancel') }}
          </button>
          <button data-cy="wi-refinalise" type="button" class="btn btn-outline-primary" :disabled="saving"
                  @click="showRefinaliseModal()"><i class="bi-save"/>&nbsp;{{ $t('general.refinalise') }}
          </button>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content class="pb-1">
      <spinner v-if="loading"/>
      <template v-if="!loading">
        <waste-identification-form :item="item"/>
      </template>
    </ui-content>
  </layout>
  <form-edit-disclaimer-modal ref="refinaliseModal" :callback="refinalise"/>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import backend from '@/util/backend'
import {ref} from 'vue'
import Spinner from '@/components/spinner'
import WasteIdentificationForm from '@/partials/waste-identification/Form'
import Notifier from '@/util/notifier'
import FormState from '@/types/formState'
import {useRouter} from 'vue-router'
import DocumentType from '@/types/documentType'
import WasteIdentificationModel from '@/models/WasteIdentificationModel'
import FormEditDisclaimerModal from '@/views/forms/shared/Edit-Disclaimer-Modal.vue'

export default {
  name:       'WasteIdentificationEdit',
  components: {FormEditDisclaimerModal, WasteIdentificationForm, Spinner, UiContent, UiBreadcrumbs, UiHeader, Layout},
  props:      {
    id: {
      type:     String,
      required: true,
    },
  },
  setup:      (props) => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.WASTE_IDENTIFICATION, BREADCRUMBS.EDIT]
    const item        = ref({})
    const loading     = ref(true)
    const date        = ref(null)
    const finalForm   = ref(false)

    backend.get(`api/documents/${props.id}`).then((r) => {
      if (r.data.type !== DocumentType.WASTE_IDENTIFICATION) {
        router.push(`/waste-identification/list`)
      }
      item.value      = r.data
      finalForm.value = item.value.state === FormState.FINISHED
      loading.value   = false
    })

    const check = () => {
      const err = WasteIdentificationModel.formCheck(item.value, FormState.isDraft(item.value.state))
      if (err) {
        const formElement = $('#waste-identification-form')[0]
        formElement.reportValidity()
        notifier.error(err)
        return false
      }

      notifier.success('toast.form_valid')
      return true
    }

    const validate = () => {
      // if we're not in a draft state, then all validations should be run
      if (!FormState.isDraft(item.value.state)) {
        return check() && validateIDs()
      } else {
        return validateIDs()
      }
    }

    const validateIDs = () => {
      const vatElements = $('.id-type-vat')
      for (let i = 0; i < vatElements.length; i++) {
        const el = vatElements[i]
        if (!el?.checkValidity()) {
          // something in the form is not valid so don't allow signing
          el?.reportValidity()
          return false
        }
      }
      return true
    }

    const saving   = ref(false)
    const router   = useRouter()
    const notifier = Notifier()
    const save     = async () => {
      saving.value = true
      if (!validate()) {
        saving.value = false
        return false
      }

      const result = await backend.put(`api/documents/${props.id}`, item.value)
      if (result.status === 200) {
        router.push(`/waste-identification/view/${props.id}`)
      } else {
        notifier.error('toast.save_failed')
      }
      saving.value = false
    }

    const cancel = () => {
      router.push(`/waste-identification/view/${props.id}`)
    }

    const refinaliseModal     = ref('refinaliseModal')
    const showRefinaliseModal = async () => {
      refinaliseModal.value.modal.open()
    }
    const refinalise          = async () => {
      saving.value = true
      if (!validate()) {
        saving.value = false
        return false
      }

      const saveResult = await backend.put(`api/documents/${props.id}`, item.value)
      if (saveResult.status === 200) {
        const finishResult = await backend.put(`api/documents/${props.id}/finish`)
        if (finishResult.status === 200) {
          notifier.success('toast.finish_successful')
          router.push(`/waste-identification/view/${props.id}`)
        } else {
          notifier.error('toast.finish_failed')
          refinaliseModal.value.modal.close()
        }
      } else {
        notifier.error('toast.save_failed')
      }
    }

    return {
      breadcrumbs,
      item,
      loading,
      date,
      save,
      saving,
      check,
      finalForm,
      cancel,
      refinaliseModal,
      showRefinaliseModal,
      refinalise,
    }
  },
}
</script>
