<template>
  <layout>
    <template #header>
      <ui-header :title="$t('grondbank.list.grondbank')">
        <template #actions>
          <div v-if="$roles([roles.USER])" class="btn-group">
            <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="actionsDropdown"
                    data-bs-toggle="dropdown" :disabled="selectedIDs.length === 0">
              {{ $t('general.actions') }}
            </button>
            <ul data-cy="grondbank-actions" class="dropdown-menu" aria-labelledby="actionsDropdown">
              <li v-if="$roles([roles.USER])" :title="notShareableSelected ? $t('forms.view.only_signed_shared') : ''">
                <button :disabled="selectedIDs.length === 0 || notShareableSelected" class="dropdown-item"
                        @click="showShare()">
                  {{ $t('forms.list.actions.share_with_driver') }}
                </button>
              </li>
              <li v-if="$roles([roles.USER])" :title="draftSelected ? $t('forms.view.draft_no_export') : ''">
                <button :disabled="selectedIDs.length === 0 || draftSelected" class="dropdown-item"
                        @click="showExport()">
                  {{ $t('forms.list.actions.export') }}
                </button>
              </li>
              <li v-if="$roles([roles.USER])">
                <button :disabled="selectedIDs.length === 0" class="dropdown-item" @click="showDuplicate()">
                  {{ $t('forms.list.actions.duplicate') }}
                </button>
              </li>
              <li v-if="$roles([roles.USER])">
                <button :disabled="selectedIDs.length === 0" class="dropdown-item" @click="showRemove()">
                  {{ $t('forms.list.actions.delete') }}
                </button>
              </li>
            </ul>
          </div>
          <button v-if="$roles([roles.USER])" data-cy="create-form" type="button" class="btn btn-outline-primary"
                  @click="addForm()"><i class="bi-plus"/>&nbsp;{{ $t('general.add_form') }}
          </button>
        </template>
      </ui-header>
    </template>
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
      </div>
      <div>
        <button class="btn btn-sm btn-outline-secondary" @click="resetGrid()">{{ $t('general.reset_filters') }}</button>
      </div>
    </div>
    <ui-content>
      <ag-grid-vue
          :serverSideDatasource="grid.datasource('api/documents/list?documentType=SOIL_BANK')"
          :grid-options="grid.defaultOptions"
          :columnDefs="xsWindow ? xsColumnDefs : columnDefs"
          class="ag-theme-quartz"
          style="min-height:100%"
          @row-clicked="rowClick"
          @selection-changed="refreshSelection"
          @grid-ready="onGridReady"
          @grid-pre-destroyed="onGridPreDestroyed"
          @filter-changed="onFilterChanged"
          :initialState="initialState"
      />
    </ui-content>
  </layout>
  <form-duplicate-modal ref="duplicateModal" :amount="selectedIDs.length" :callback="duplicate"/>
  <form-delete-modal ref="removeModal" :amount="selectedIDs.length" :callback="remove"/>
  <form-share-modal ref="shareModal" :multiple="selectedIDs.length > 1" :amount="selectedIDs.length" :callback="share"
                    :id="selectedIDs.length === 1 ? selectedIDs[0] : null" :document-type="DocumentType.GRONDBANK"
                    @reload="reload"/>
  <form-export-modal ref="exportModal" :amount="selectedIDs.length" :callback="exportForms"/>
  <form-set-state-empty-modal ref="setStateEmptyModal" :amount="selectedIDs.length" :callback="setStateEmpty"/>
</template>

<script>

import {inject, onBeforeUnmount, ref} from 'vue'
import backend from '@/util/backend'
import Layout from '@/components/layout'
import {AgGridVue} from 'ag-grid-vue3'
import {useRouter} from 'vue-router'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import UiHeader from '@/components/ui-header'
import UiContent from '@/components/ui-content'
import FormDuplicateModal from '@/views/forms/shared/Duplicate-Modal'
import FormDeleteModal from '@/views/forms/shared/Delete-Modal'
import FormShareModal from '@/views/forms/shared/Share-Modal'
import FormExportModal from '@/views/forms/shared/Export-Modal'
import FormSetStateEmptyModal from '@/views/forms/shared/Set-State-Empty-Modal'
import {DateFormatter} from '@/services/dateFormatter'
import Notifier from '@/util/notifier'
import FormState from '@/types/formState'
import roles from '@/util/roles'
import '@vuepic/vue-datepicker/dist/main.css'
import DocumentType from '@/types/documentType'
import GrondbankModel from '@/models/GrondbankModel'
import grid from '@/util/grid'
import session from '@/util/session'
import stateColorRenderer from '@/util/stateColorRenderer'
import ExportType from '@/types/exportType'

export default {
  name:       'GrondbankListUser',
  computed:   {
    grid() {
      return grid
    },
    DocumentType() {
      return DocumentType
    },
  },
  components: {
    FormSetStateEmptyModal,
    FormExportModal,
    FormShareModal,
    FormDeleteModal,
    FormDuplicateModal,
    UiContent,
    UiHeader,
    Layout,
    // eslint-disable-next-line
    stateColorRenderer,
    UiBreadcrumbs,
    AgGridVue,
  },
  setup:      () => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.GRONDBANK]
    const items       = ref([])
    const $t          = inject('$t')
    const $isSafari   = inject('$isSafari')
    const notifier    = Notifier()
    session.setActiveForm('grondbank')

    const xsWindow = ref(window.innerWidth < 576)
    const resize   = () => {
      xsWindow.value = window.innerWidth < 576
      setTimeout(() => {
        api.sizeColumnsToFit({'defaultMinWidth': 200})
      }, 1)
    }
    window.addEventListener('resize', resize)
    onBeforeUnmount(() => {
      window.removeEventListener('resize', resize)
    })

    const xsColumnDefs = [
      {
        headerName:  $t('grondbank.list.table.key'),
        field:       'id',
        valueGetter: (params) => {
          return 'DGF-GB-' + params.data.id
        },
      },
      {
        headerName:  $t('grondbank.list.table.state'),
        valueGetter: (params) => {
          return $t('form.state.' + params.data.state)
        },
      },
    ]

    const columnDefs = [
      {
        headerName:  $t('forms.list.table.key'),
        field:       'id',
        valueGetter: (params) => {
          return 'DGF-GB-' + params.data.id
        },
      },
      {
        headerName:     $t('forms.list.table.date'),
        filter:         'agDateColumnFilter',
        field:          'transportDate',
        maxWidth:       250,
        filterParams:   {
          minValidYear:  2000,
          maxValidYear:  2099,
          filterOptions: ['inRange'],
        },
        valueGetter:    (params) => {
          return new Date(params.data.transportDate)
        },
        valueFormatter: (params) => {
          return DateFormatter.formatDate(params.data.transportDate)
        },
      },
      {
        headerName: $t('forms.list.table.description'),
        field:      'wasteDescription',
      },
      {
        headerName: $t('forms.list.table.permissionId'),
        field:      'permissionId',
      },
      {headerName: $t('forms.list.table.from'), field: 'from'},
      {headerName: $t('forms.list.table.to'), field: 'to'},
      {
        headerName:   $t('forms.list.table.state'),
        field:        'state',
        maxWidth:     400,
        cellRenderer: 'stateColorRenderer',
        filter:       'agSetColumnFilter',
        filterParams: {
          values:         FormState.getAll(true),
          valueFormatter: (val) => {
            return $t('form.state.' + val.value)
          },
        },
      },
    ]

    if (roles.hasOnlyRole(roles.DRIVER)) {
      const orgColumn = {
        headerName: $t('grondbank.list.table.organisation'),
        field:      'organisation',
      }
      columnDefs.splice(1, 0, orgColumn)
    }

    const gridKey            = 'grondbank-list-user'
    const initialState       = ref(session.loadFormState(gridKey))
    const onGridPreDestroyed = (params) => {
      session.saveFormState(gridKey, params.state)
    }
    const onFilterChanged    = (event) => {
      session.saveFormState(gridKey, event.api.getState())
    }

    let api           = null
    const onGridReady = (params) => {
      api = params.api
      api.deselectAll() // if we can correctly get selection from saved state we can re-evaluate this
      api.sizeColumnsToFit({'defaultMinWidth': 200})
    }
    onBeforeUnmount(() => {
      api = null
    })
    const reload    = () => {
      api?.refreshServerSide()
      api?.deselectAll()
    }
    const resetGrid = () => {
      api?.setFilterModel({
        'state': {
          filterType: 'set',
          values:     FormState.getAll(false),
        },
      })
      api?.onFilterChanged()
      session.saveFormState(gridKey, null)
    }

    const router   = useRouter()
    const rowClick = (event) => {
      if (event.data) {
        router.push(`/grondbank/view/${event.data.id}`)
      }
    }

    const addForm = async () => {
      const result = await backend.post('api/documents', JSON.parse(JSON.stringify(GrondbankModel.empty)))
      if (result.status === 200) {
        router.push(`/grondbank/edit/${result.data.id}`)
      } else {
        notifier.error('toast.form_create_failed')
      }
    }

    const duplicateModal = ref('duplicateModal')
    const showDuplicate  = () => {
      duplicateModal.value.modal.open()
    }
    const duplicate      = () => {
      const ids = api.getSelectedRows().map((row) => {
        return row.id
      })
      if (ids.length > 0) {
        backend.post('api/documents/batch', {
          'action': 'COPY',
          'ids':    ids,
        }).then((result) => {
          if (result.status === 200) {
            notifier.success('toast.duplication_successful')
          } else {
            notifier.error('toast.duplication_failed')
          }
        }).finally(() => {
          duplicateModal.value.modal.close()
          reload()
        })
      }
    }

    const shareModal = ref('shareModal')
    const showShare  = () => {
      shareModal.value.modal.open()
    }
    const share      = async (shareWith) => {
      const ids = api.getSelectedRows().filter((row) => {
        return row.state !== 'DRAFT'
      }).map((row) => {
        return row.id
      })
      if (ids.length > 0) {
        await backend.post('api/documents/batch', {
          'action': 'SHARE_DRIVER',
          'ids':    ids,
          'params': shareWith,
        }).then((result) => {
          if (result.status === 200) {
            notifier.success('toast.sharing_successful')
          } else {
            notifier.error('toast.sharing_failed')
          }
        }).finally(() => {
          shareModal.value.modal.close()
          reload()
        })
      }
    }

    const exportModal = ref('exportModal')
    const showExport  = () => {
      exportModal.value.modal.open()
    }
    const exportForms = (language, type) => {
      const ids = api.getSelectedRows().filter((row) => {
        return row.state !== 'DRAFT'
      }).map((row) => {
        return row.id
      })
      if (ids.length > 0) {
        switch (type) {
          case ExportType.PDF: {
            exportPdf(ids, language)
            return
          }
          case ExportType.JSON: {
            exportJson(ids)
            return
          }
          case ExportType.CSV: {
            if ($isSafari()) {
              const route = router.resolve(`/export-csv/${ids.join(',')}`)
              window.open(route.href, '_blank')
            } else {
              exportCsv(ids)
            }
            return
          }
        }
      }
    }

    const exportPdf = (ids, language) => {
      backend.post('api/documents/batch', {
        'action': 'EXPORT_PDF',
        'ids':    ids,
        'params': {
          'language': language,
        },
      }, {responseType: 'blob'}).then((result) => {
        if (result.status === 200) {
          const blob = new Blob([result.data], {type: 'application/pdf'})
          const url  = URL.createObjectURL(blob)
          window.open(url)
        } else {
          notifier.error('toast.exporting_failed')
        }
      }).finally(() => {
        exportModal.value.modal.close()
      })
    }

    const exportJson = (ids) => {
      backend.post('api/documents/batch', {
        'action': 'EXPORT_JSON',
        'ids':    ids,
      }).then((result) => {
        if (result.status === 200) {
          const data = JSON.stringify(result.data[0])
          const blob = new Blob([data], {type: 'application/json'})
          const url  = URL.createObjectURL(blob)
          window.open(url)
        } else {
          notifier.error('toast.exporting_failed')
        }
      }).finally(() => {
        exportModal.value.modal.close()
      })
    }

    const exportCsv = (ids) => {
      backend.post('api/documents/batch', {
        'action': 'EXPORT_CSV',
        'ids':    ids,
      }).then((result) => {
        if (result.status === 200) {
          const blob      = new Blob([result.data])
          const url       = URL.createObjectURL(blob)
          const a         = document.createElement('a')
          a.style.display = 'none'
          a.href          = url
          a.download      = `export.csv`
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        } else {
          notifier.error('toast.exporting_failed')
        }
      }).finally(() => {
        exportModal.value.modal.close()
      })
    }

    const removeModal = ref('removeModal')
    const showRemove  = () => {
      removeModal.value.modal.open()
    }
    const remove      = () => {
      const ids = api.getSelectedRows().filter((row) => {
        return FormState.canDelete(row.state)
      }).map((row) => {
        return row.id
      })
      if (ids.length > 0) {
        backend.post('api/documents/batch', {
          'action': 'DELETE',
          'ids':    ids,
        }).then((result) => {
          if (result.status === 200) {
            notifier.success('toast.deleting_successful')
          } else {
            notifier.error('toast.deleting_failed')
          }
        }).finally(() => {
          removeModal.value.modal.close()
          reload()
        })
      } else {
        removeModal.value.modal.close()
      }
    }

    const setStateEmptyModal = ref('setStateEmptyModal')
    const showSetStateEmpty  = () => {
      setStateEmptyModal.value.modal.open()
    }
    const setStateEmpty      = () => {
      const ids = api.getSelectedRows().filter((row) => {
        return row.state === 'DRAFT'
      }).map((row) => {
        return row.id
      })
      if (ids.length > 0) {
        backend.post('api/documents/batch', {
          'action': 'EMPTY',
          'ids':    ids,
        }).then((result) => {
          if (result.status === 200) {
            notifier.success('toast.update_successful')
          } else {
            notifier.error('toast.update_failed')
          }
        }).finally(() => {
          setStateEmptyModal.value.modal.close()
          reload()
        })
      }
    }

    const draftSelected        = ref(false)
    const notShareableSelected = ref(false)

    const selectedIDs      = ref([])
    const selection        = ref([])
    const refreshSelection = (params) => {
      selection.value            = params.api.getSelectedRows()
      selectedIDs.value          = params.api.getSelectedRows().map((row) => {
        return row.id
      })
      draftSelected.value        = items.value.filter((item) => {
        return selectedIDs.value.indexOf(item.id) > -1 && item.state === 'DRAFT'
      }).length > 0
      notShareableSelected.value = selection.value.filter((item) => {
        return !FormState.canShare(item.state)
      }).length > 0
    }

    return {
      breadcrumbs,
      reload,
      resetGrid,
      items,
      xsWindow,
      xsColumnDefs,
      columnDefs,
      rowClick,
      initialState,
      onGridPreDestroyed,
      onFilterChanged,
      onGridReady,
      addForm,
      refreshSelection,
      draftSelected,
      notShareableSelected,
      selectedIDs,
      duplicateModal,
      showDuplicate,
      duplicate,
      removeModal,
      showRemove,
      remove,
      shareModal,
      showShare,
      share,
      exportModal,
      showExport,
      exportForms,
      setStateEmptyModal,
      showSetStateEmpty,
      setStateEmpty,
    }
  },
}
</script>
