import DocumentType from '../types/documentType'
import CountryType from '@/types/countryType'

const checkAlways = (form) => {
  const formElement = $('#annex-7-form')[0]
  if (!formElement) {
    return 'toast.form_missing'
  }
  if (!formElement.checkValidity()) {
    formElement.reportValidity()
    // something in the form is not valid
    return 'toast.form_incomplete'
  }
  if (!validWasteAmounts(form)) {
    return 'toast.invalid_amount_ton_m3'
  }
  if (!validWasteIdentifications(form.wasteIdentifications)) {
    return 'toast.invalid_waste_identifications'
  }
  if (!validCountries(form.countriesConcerned)) {
    return 'toast.invalid_countries'
  }
}

const validWasteAmounts = (form) => {
  return !(!form.amountTon && !form.amountM3);
}

const validWasteIdentifications = (wasteIdentifications) => {
  if (!(wasteIdentifications.length > 0)) {
    return false
  }
  for (let i = 0; i < wasteIdentifications.length; i++) {
    if (!wasteIdentifications[i].type || !wasteIdentifications[i].value) {
      return false
    }
  }
  return true
}

const validCountries = (countries) => {
  if (!(countries.length >= 2)) {
    return false
  }
  const exportCountries = countries.filter((c) => {
    return c.type === CountryType.EXPORT
  })
  if (!(exportCountries.length === 1) || !exportCountries[0].value) {
    return false
  }
  const destinationCountries = countries.filter((c) => {
    return c.type === CountryType.DESTINATION
  })
  return !(!(destinationCountries.length === 1) || !destinationCountries[0].value)
}

const checkToSign = (form) => {
  const localOffset = new Date().getTimezoneOffset() / -60 // offset to UTC in hours
  let date = new Date(form.transportDate)
  date.setHours(date.getHours() + localOffset)
  date = date.setUTCHours(0, 0, 0, 0)
  const today = new Date().setUTCHours(0, 0, 0, 0)
  // if date is in past
  if (date < today) {
    return 'toast.date_in_past'
  }
}

const Annex7Model = {
  empty: {
    id: null,
    externalId: null,
    type: DocumentType.ANNEX_7,
    state: 'DRAFT',
    notes: '',
    transportDate: null,
    removalOperation: null,
    description: null,
    amount: null,
    producerIdentification: {
      language: 'NL',
      address: null,
      shipmentAddress: null,
    },
    organiserIdentification: {
      language: 'NL',
      address: null,
    },
    transporterIdentifications: [
      {
        language: 'NL',
        address: null,
      },
    ],
    processorIdentification: {
      language: 'NL',
      address: null,
    },
    recoveryFacility: {},
    wasteIdentifications: [],
    countriesConcerned: [],
    organiserSignature: null,
    processorSignature: null,
    recoveryFacilitySignature: null,
  },
  formCheck: (form, allChecks = false) => {
    let r = checkAlways(form)
    if (r) {
      return r
    }
    if (allChecks) {
      r = checkToSign(form)
      if (r) {
        return r
      }
    }
  },
}

export default Annex7Model
