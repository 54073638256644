<template>
  <layout>
    <template #header>
      <ui-header :title="$t('annex_7.edit.title', {id})" :back-route="`/annex-7/view/${id}`">
        <template #actions>
          <button data-cy="check-a7" type="button" class="btn btn-outline-secondary" @click="check()"><i
              class="bi-check"/>&nbsp;{{ $t('form.check_form') }}
          </button>
          <button data-cy="save-a7" type="button" class="btn btn-outline-primary" :disabled="saving" @click="save()"
                  form="annex-7-form"><i class="bi-save"/>&nbsp;{{ $t('general.save') }}
          </button>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs"/>
    <ui-content class="pb-1">
      <spinner v-if="loading"/>
      <template v-if="!loading">
        <annex7-form :item="item"/>
      </template>
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import backend from '@/util/backend'
import {ref} from 'vue'
import Spinner from '@/components/spinner'
import Notifier from '@/util/notifier'
import FormState from '@/types/formState'
import {useRouter} from 'vue-router'
import DocumentType from '@/types/documentType'
import Annex7Form from '@/partials/annex7/Form.vue'
import Annex7Model from '@/models/Annex7Model'

export default {
  name:       'Annex7Edit',
  components: {
    Annex7Form,
    Spinner,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout,
  },
  props:      {
    id: {
      type:     String,
      required: true,
    },
  },
  setup:      (props) => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.ANNEX_7, BREADCRUMBS.EDIT]
    const item        = ref({})
    const loading     = ref(true)
    const date        = ref(null)
    backend.get(`api/documents/${props.id}`).then((r) => {
      if (r.data.type !== DocumentType.ANNEX_7) {
        router.push(`/annex-7/list`)
      }
      // if the form is in an end state we can't show edit page anymore, only view page
      if (FormState.isEndState(r.data.state)) {
        router.push(`/annex-7/view/${props.id}`)
      }
      item.value    = r.data
      loading.value = false
    })

    const check = () => {
      const err = Annex7Model.formCheck(item.value, FormState.isDraft(item.value.state))
      if (err) {
        if (err === 'toast.invalid_waste_identifications') {
          $('#a7-add-identification').get(0).scrollIntoView({behavior: 'smooth'})
        }
        const formElement = $('#annex-7-form')[0]
        formElement.reportValidity()
        notifier.error(err)
        return false
      }

      notifier.success('toast.form_valid')
      return true
    }

    const validate = () => {
      // if we're not in a draft state, then all validations should be run
      if (!FormState.isDraft(item.value.state)) {
        return check() && validateIDs()
      } else {
        return validateIDs()
      }
    }

    const validateIDs = () => {
      const vatElements = $('.id-type-vat')
      for (let i = 0; i < vatElements.length; i++) {
        const el = vatElements[i]
        if (!el?.checkValidity()) {
          // something in the form is not valid so don't allow signing
          el?.reportValidity()
          return false
        }
      }
      return true
    }

    const saving   = ref(false)
    const router   = useRouter()
    const notifier = Notifier()
    const save     = async () => {
      saving.value = true
      if (!validate()) {
        saving.value = false
        return false
      }

      const result = await backend.put(`api/documents/${props.id}`, item.value)
      if (result.status === 200) {
        router.push(`/annex-7/view/${props.id}`)
      } else {
        notifier.error('toast.save_failed')
      }
      saving.value = false
    }

    return {
      breadcrumbs,
      item,
      loading,
      date,
      save,
      saving,
      check,
    }
  },
}
</script>
