<template>
  <header class="bg-light pb-0">
    <div class="d-flex justify-content-between">
      <ul class="nav nav-tabs">
        <li class="nav-item" v-for="formType in formTypes" :key="formType">
          <a class="nav-link cursor-pointer" :class="formType.active ? 'active' : ''"
             :href="formType.link">{{ formType.label }}</a>
        </li>
      </ul>
      <div v-if="activeFormType?.sjablonen" class="float-end">
        <button class="btn btn-outline-primary" data-cy="create-form" type="button"
                @click="$emit('showSelectTemplate')"><i class="bi-plus"/>&nbsp;{{ $t('general.create_form') }}
        </button>
      </div>
    </div>
  </header>
</template>

<script>
import {computed, inject} from 'vue'

export default {
  name:  'ui-list-driver-tabs',
  emits: ['showSelectTemplate'],
  setup: () => {
    const $t    = inject('$t')
    const $flag = inject('$flag')

    const formTypes = [
      {
        label:     $t('tabs.waste'),
        link:      '#/waste-identification/list',
        sjablonen: $flag('SJABLONEN'),
        active:    window.location.href.indexOf('#/waste-identification/list') > -1,
      },
    ]

    if ($flag('GRONDBANK')) {
      formTypes.push({
        label:     $t('tabs.GB'),
        link:      '#/grondbank/list',
        sjablonen: false,
        active:    window.location.href.indexOf('#/grondbank/list') > -1,
      })
    }

    const activeFormType = computed(() => {
      return formTypes.find((ft) => {
        return ft.active
      })
    })

    return {
      formTypes,
      activeFormType,
    }
  },
}

</script>