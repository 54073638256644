<template>
  <ui-modal ref="modal" :on-open="fetch" no-confirm>
    <template #title>
      {{ $t('forms.summary_modal.title') }}
    </template>
    <template #default>
      <template v-if="loading">
        <spinner/>
      </template>
      <template v-else>
        <ui-view-card-field
            v-for="(field, index) in fields"
            :key="index"
            :label="field.label"
            :model-value="field.value"
            readonly
        />
        <hr/>
        <div class="d-flex justify-content-center">
          <button id="generateQRCode" type="button" :class="exporting ? 'disabled' : ''"
                  class="btn btn-outline-secondary" @click="getRidesExport()"
          >
            {{ $t('forms.summary_modal.export_rides') }}
          </button>
        </div>
      </template>
    </template>
  </ui-modal>
</template>

<script>

import {inject, ref} from 'vue'
import UiModal from '@/components/ui-modal'
import UiViewCardField from '@/components/ui-view-card-field.vue'
import {DateFormatter} from '@/services/dateFormatter'
import backend from '@/util/backend'
import Notifier from '@/util/notifier'
import router from '@/router/router'
import Spinner from '@/components/spinner.vue'

export default {
  name:       'FormSummaryModal',
  components: {Spinner, UiViewCardField, UiModal},
  props:      {
    form: {
      type:     Object,
      required: true,
    },
  },
  setup:      (props) => {
    const modal     = ref('modal')
    const $isSafari = inject('$isSafari')
    const $t        = inject('$t')
    const notifier  = Notifier()

    const fields  = ref([])
    const ids     = []
    const loading = ref(true)
    const fetch   = async () => {
      fields.value  = []
      loading.value = true
      const summary = await backend.get(`api/documents/${props.form.id}/rides/summary`)
      fields.value.push({
        'label': $t('forms.summary_modal.permission_id'),
        'value': summary.data.permissionId,
      })
      fields.value.push({
        'label': $t('forms.summary_modal.first_transport_date'),
        'value': DateFormatter.formatDate(summary.data.firstTransport),
      })
      fields.value.push({
        'label': $t('forms.summary_modal.last_transport_date'),
        'value': DateFormatter.formatDate(summary.data.lastTransport),
      })
      fields.value.push({
        'label': $t('forms.summary_modal.volumeCubicMeters'),
        'value': summary.data.volumeCubicMeters + ' m³',
      })
      ids.push(...summary.data.documentIds)
      loading.value = false
    }

    const exporting      = ref(false)
    const getRidesExport = () => {
      if ($isSafari()) {
        const route = router.resolve(`/export-csv/${ids.join(',')}`)
        window.open(route.href, '_blank')
      } else {
        exportCsv(ids)
      }
    }

    const exportCsv = (ids) => {
      exporting.value = true
      backend.post('api/documents/batch', {
        'action': 'EXPORT_CSV',
        'ids':    ids,
      }).then((result) => {
        if (result.status === 200) {
          const blob      = new Blob([result.data])
          const url       = URL.createObjectURL(blob)
          const a         = document.createElement('a')
          a.style.display = 'none'
          a.href          = url
          a.download      = `export.csv`
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        } else {
          notifier.error('toast.exporting_failed')
        }
      }).finally(() => {
        exporting.value = false
      })
    }

    return {
      modal,
      fetch,
      loading,
      fields,
      getRidesExport,
      exporting,
    }
  },
}
</script>
