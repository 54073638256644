<template>
  <ui-modal size="lg" id="editDisclaimerModal" ref="modal" :callback="cb">
    <template #title>
      {{ $t('forms.edit_disclaimer_modal.title') }}
    </template>
    <template #default>
      <div class="pb-2">
        {{ $t('forms.edit_disclaimer_modal.disclaimer_1') }}
      </div>
      <div>
        {{ $t('forms.edit_disclaimer_modal.disclaimer_2') }}
        <ul>
          <li>{{ $t('forms.edit_disclaimer_modal.disclaimer_3') }}</li>
          <li>{{ $t('forms.edit_disclaimer_modal.disclaimer_4') }}</li>
        </ul>
      </div>
      <div class="pb-2">
        {{ $t('forms.edit_disclaimer_modal.disclaimer_5') }}
        <ul>
          <li>{{ $t('forms.edit_disclaimer_modal.disclaimer_6') }}</li>
        </ul>
      </div>
    </template>
  </ui-modal>
</template>

<script>

import {ref} from 'vue'
import UiModal from '@/components/ui-modal'

export default {
  name:       'FormEditDisclaimerModal',
  components: {
    UiModal,
  },
  props:      {
    callback: {
      type:     Function,
      required: true,
    },
  },
  setup:      (props) => {
    const modal = ref('modal')

    const cb = async () => {
      await props.callback()
    }

    return {
      modal,
      cb,
    }
  },
}
</script>
