import backend from '@/util/backend'
import session from '@/util/session'
import i18n, {languages} from '@/util/i18n'

const getTranslate = () => {
  const user = session.getUser()
  return i18n(user?.language ?? languages.NL)
}

const MatisLocationTypes = {
  cache: [],
  clear: () => {
    MatisLocationTypes.cache = []
  },
  call: null,
  get: async () => {
    if (MatisLocationTypes.cache.length === 0) {
      let result
      if (MatisLocationTypes.call !== null) {
        result = await MatisLocationTypes.call
      } else {
        MatisLocationTypes.call = backend.get('api/matis/locationTypes')
        result = await MatisLocationTypes.call
      }
      MatisLocationTypes.clear()
      result.data.forEach((val) => {
        MatisLocationTypes.cache.push({value: val, label: getTranslate()('matis_location_types.' + val)})
      })
    }
    return MatisLocationTypes.cache
  }
}

export default MatisLocationTypes
