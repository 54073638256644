<template>
  <div class="card col-12 p-0" :class="type === '' ? 'col-lg-6' : type">
    <h5 class="card-header d-flex justify-content-between">
      <slot name="title">
        {{ title }}
        <i v-if="help" style="font-size: 0.75rem; padding-right: 0.5rem;"
           class="bi-question-circle-fill"
           :title="help"/>
      </slot>
      <slot name="actions"/>
    </h5>
    <div class="card-body">
      <h6 class="card-subtitle text-muted">
        <slot name="subtitle"/>
      </h6>
      <div class="card-text">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name:       'ui-view-card',
  components: {},
  props:      {
    title: {
      type:    String,
      default: '',
    },
    type:  {
      type:    String,
      default: '',
    },
    help:  {
      type:    String,
      default: '',
    },
  },
  setup:      () => {
    return {}
  },
}
</script>